@tailwind base;
@tailwind components;
@tailwind utilities;

.fc-event-title {
	overflow-wrap: break-word !important;

	padding: 0 1px;
	white-space: normal;
}

.organization-background{
	background-image: url('/public/images/javibackground.svg');
}
