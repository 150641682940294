@media print {
    body * {
      visibility: hidden;
    }
  
    #printable-area, #printable-area * {
      visibility: visible;
    }
  
    #printable-area {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      padding: 0;
      margin: 0;
    }
  
    canvas {
      width: 100% !important;
      height: auto !important;
      page-break-inside: avoid; /* Prevents page breaks inside canvas */
    }
  
    @page {
      size: auto;
      margin: 0;
    }
      /* Ensure no extra space is added */
  html, body {
    height: auto;
    margin: 0;
    padding: 0;
  }

  /* Prevent page breaks that cause white pages */
  #printable-area {
    page-break-after: avoid;
  }
  }
  